import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { OfferSaverContent } from '../../offer-saver.model';
import { EmailOfferModalComponent } from '../email-offer-modal/email-offer-modal.component';

@Component({
  selector: 'wl-offer-saver-button',
  standalone: true,
  templateUrl: 'offer-saver-button.component.html',
  imports: [CommonModule, FontAwesomeModule],
})
export class OfferSaverButtonComponent {
  @Input({ required: true }) content!: OfferSaverContent;

  brand = inject(ENVIRONMENT_CONFIG).brand;
  modalService = inject(NgbModal);

  get buttonClass(): string {
    return this.brand === 'ed' ? 'btn-link' : 'btn-outline-primary';
  }

  openEmailOfferModal(): void {
    const emailOfferModal = this.modalService.open(EmailOfferModalComponent);
    emailOfferModal.componentInstance.offerSaverModalContent =
      this.content.modals;
  }
}
