import { mapGenericModalProperties } from '@innogy/common-ui/shared';
import {
  GenericModalSources,
  type GenericModalProps,
} from '@innogy/common-ui/shared/interfaces';
import { getDroplinkField, getFieldValue } from '@innogy/core-jss-utils';
import type {
  ComponentFields,
  ComponentRendering,
} from '@sitecore-jss/sitecore-jss-angular';

export class OfferSaverContent {
  buttonLabel: string;
  modals: OfferSaverModalContent;

  constructor(rendering: ComponentRendering) {
    this.buttonLabel = getFieldValue(rendering, 'OfferSaverButtonLabel', '');
    this.modals = new OfferSaverModalContent(rendering);
  }
}

export class OfferSaverModalContent {
  emailOffer: EmailOfferModalContent;
  offerEmailed: GenericModalProps;
  offerEmailFailed: GenericModalProps;

  constructor(rendering: ComponentRendering) {
    const emailOfferModalRendering = getDroplinkField(
      rendering.fields,
      'EmailOfferModal'
    );
    this.emailOffer = new EmailOfferModalContent(emailOfferModalRendering);

    const offerEmailedModalRendering = getDroplinkField(
      rendering.fields,
      'OfferEmailedModal'
    );
    this.offerEmailed = mapGenericModalProperties(
      GenericModalSources.OFFER_EMAILED,
      offerEmailedModalRendering
    );

    const offerEmailFailedModalRendering = getDroplinkField(
      rendering.fields,
      'OfferEmailFailedModal'
    );
    this.offerEmailFailed = mapGenericModalProperties(
      GenericModalSources.OFFER_EMAIL_FAILED,
      offerEmailFailedModalRendering
    );
  }
}

export class EmailOfferModalContent {
  title: string;
  explanation: string;
  emailAddressField: {
    label: string;
    placeholder: string;
    note: string;
    errorMessages: {
      missing: string;
      invalid: string;
    };
  };
  buttonLabels: {
    emailOffer: string;
    dismiss: string;
  };

  constructor(rendering?: ComponentFields) {
    this.title = getFieldValue(rendering, 'Title', '');
    this.explanation = getFieldValue(rendering, 'Explanation', '');
    this.emailAddressField = {
      label: getFieldValue(rendering, 'EmailAddressFieldLabel', ''),
      placeholder: getFieldValue(rendering, 'EmailAddressFieldPlaceholder', ''),
      note: getFieldValue(rendering, 'EmailAddressFieldNote', ''),
      errorMessages: {
        missing: getFieldValue(
          rendering,
          'MissingEmailAddressErrorMessage',
          ''
        ),
        invalid: getFieldValue(
          rendering,
          'InvalidEmailAddressErrorMessage',
          ''
        ),
      },
    };
    this.buttonLabels = {
      emailOffer: getFieldValue(rendering, 'EmailOfferButtonLabel', ''),
      dismiss: getFieldValue(rendering, 'DismissButtonLabel', ''),
    };
  }
}
